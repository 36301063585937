import React, { useEffect } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const CustomerSpeak = () => {

    useEffect(() => {
          document.title = `Customer Speak | Agile Seekers`;
          document.getElementById('bodywrap').className='cuspmain';
    },[]);

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };


    return (
        <>
            <Navbar />

                <section className='csmainwrap'>
                    <Container>
                        <Row className='cstitle'>
                            <Col>
                                <h3>What Our Customers Are Saying</h3>
                                <p>Real feedback from our satisfied customers.</p>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className='testiwrap'>
                        <Row>
                            <Col md={4}>
                                <Col className='testibox'>
                                    <div className='rating_bar'>
                                        <div className='rating-dis' style={{width: '90%'}}></div>
                                    </div>     
                                    <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                    <div>
                                        <div>
                                            <h4>Mames Mary</h4>
                                            <h5>Designer</h5>
                                        </div>
                                        <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                    </div>
                                </Col>
                                <Image src="assets/images/testi1.png" className='img-fluid testiuser' alt='testi1'/>
                            </Col>
                            <Col md={4}>
                                <Col className='testibox'>
                                    <div className='rating_bar'>
                                        <div className='rating-dis' style={{width: '90%'}}></div>
                                    </div>     
                                    <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                    <div>
                                        <div>
                                            <h4>Rhys Chandler</h4>
                                            <h5>Science Student</h5>
                                        </div>
                                        <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                    </div>
                                </Col>
                                <Image src="assets/images/testi2.png" className='img-fluid testiuser' alt='testi2'/>
                            </Col>
                            <Col md={4}>
                                <Col className='testibox'>
                                    <div className='rating_bar'>
                                        <div className='rating-dis' style={{width: '90%'}}></div>
                                    </div>     
                                    <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                    <div>
                                        <div>
                                            <h4>Mames Mary</h4>
                                            <h5>Designer</h5>
                                        </div>
                                        <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                    </div>
                                </Col>
                                <Image src="assets/images/testi3.png" className='img-fluid testiuser' alt='testi3'/>
                            </Col>                                                        
                        </Row>
                    </Container>
                    <Container>
                        <Row className='cstitle'>
                            <Col>
                                <h3>Hear From Our Customers</h3>
                                <p>Real feedback from our satisfied customers.</p>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row className='hfocbox'>
                            <Slider {...settings}>
                                <Col>
                                    <Image src="assets/images/quote.png" className='img-fluid' alt='quote'/>  
                                    <p>"Project management can be defined as a way of developing structure in a complex project, where the independent variables of time, cost, resources and human behavior come together."</p>
                                    <h5>Mason Lyons</h5>                                
                                </Col>
                                <Col>
                                    <Image src="assets/images/quote.png" className='img-fluid' alt='quote'/>  
                                    <p>"Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>
                                    <h5>Mames Mary</h5>                                
                                </Col>
                                <Col>
                                    <Image src="assets/images/quote.png" className='img-fluid' alt='quote'/>  
                                    <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"</p>
                                    <h5>Rhys Chandler</h5>                                
                                </Col>                                                                
                            </Slider> 
                        </Row>
                    </Container>
                    <Container>
                        <Row className='cstitle'>
                            <Col>
                                <h3>Want to share your experience with us?</h3>
                                <p>Submit Your Testimonial</p>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col md={3} sm={6} xs={6}>
                                <Row className='playbox'>
                                    <Col>
                                        <Link to="/">
                                            <Image src="assets/images/play.png" className='img-fluid playicn' alt='play'/>  
                                            <Image src="assets/images/vid1.png" className='img-fluid thumbnail' alt='vid1'/>                                     
                                        </Link>
                                    </Col>
                                </Row> 
                            </Col>
                            <Col md={3} sm={6} xs={6}>
                                <Row className='playbox'>
                                    <Col>
                                        <Link to="/">
                                            <Image src="assets/images/play.png" className='img-fluid playicn' alt='play'/>  
                                            <Image src="assets/images/vid2.png" className='img-fluid thumbnail' alt='vid2'/>                                     
                                        </Link>
                                    </Col>
                                </Row> 
                            </Col>
                            <Col md={3} sm={6} xs={6}>
                                <Row className='playbox'>
                                    <Col>
                                        <Link to="/">
                                            <Image src="assets/images/play.png" className='img-fluid playicn' alt='play'/>  
                                            <Image src="assets/images/vid3.png" className='img-fluid thumbnail' alt='vid3'/>                                     
                                        </Link>
                                    </Col>
                                </Row> 
                            </Col>
                            <Col md={3} sm={6} xs={6}>
                                <Row className='playbox'>
                                    <Col>
                                        <Link to="/">
                                            <Image src="assets/images/play.png" className='img-fluid playicn' alt='play'/>  
                                            <Image src="assets/images/vid4.png" className='img-fluid thumbnail' alt='vid4'/>                                     
                                        </Link>
                                    </Col>
                                </Row> 
                            </Col>                                                                                    
                        </Row>
                    </Container>

                </section>

            <Footer />

        </>
    )        

}
    export default CustomerSpeak;    