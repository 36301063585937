import React, { useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

const Terms = () => {

    useEffect(() => {
          document.title = `Terms & Conditions | Agile Seekers`;
          document.getElementById('bodywrap').className='termsmain';
    },[]);




    return (
        <>
            <Navbar />

            <section>
                <Container>
                    <Row>
                        <Col className='term-top'>
                            <h2>Terms and Conditions</h2>
                            <h6>Last updated: July 23, 2023</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col  className='term-content'>
                            <p>Welcome to Agile Siker. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>

                            <h3>1. Acceptance of Terms</h3>                            
                            <p>By using Agile Siker, you accept these terms and conditions in full. If you do not agree with these terms, please refrain from using our services.</p>

                            <h3>2. Changes to Terms</h3>
                            <p>We reserve the right to modify these terms at any time. Any changes will be posted on this page, and your continued use of the service after such modifications will constitute your acceptance of the new terms.</p>

                            <h3>3. User Responsibilities</h3>
                            <p>You agree to use Agile Siker only for lawful purposes. You must not use our services in a way that may cause damage to the website or impairment of the availability or accessibility of the services.</p>

                            <h3>4. Intellectual Property</h3>
                            <p>All content, trademarks, and other intellectual property rights associated with Agile Siker are owned by or licensed to us. You may not reproduce, distribute, or create derivative works from any content without our explicit permission.</p>                                                        

                            <h3>5. Limitations of Liability</h3>                            
                            <p>Agile Siker is not liable for any direct, indirect, or consequential loss or damage arising from the use of, or inability to use, our services. We do not guarantee that the service will be uninterrupted or error-free.</p>

                            <h3>6. Indemnification</h3>
                            <p>You agree to indemnify, defend, and hold harmless Agile Siker and its affiliates from any claims, damages, obligations, losses, liabilities, costs, or debt incurred as a result of your use of our services or violation of these terms.</p>

                            <h3>7. Governing Law</h3>
                            <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Your Location].</p>

                            <h3>8. Contact Information</h3>
                            <p>If you have any questions about these terms and conditions, please contact us at [Your Contact Email] or through our contact page.</p>                                                                                    
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />

        </>
    )        

}
    export default Terms;    