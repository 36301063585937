import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Row, Col, Image, Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';



function CollapsibleExample() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);    

    return (

    <>
    <section className='topbar'>
        <Container>
            <Row>
                <Col md={4} sm={4} className='phone'>
                <img src="assets/images/india.png" className="img-fluid" alt="country" />                
                <Link to="tel:911234567890">+91 123 4567 890</Link>
                </Col>
                <Col md={4} sm={4} className='mail px-0'>
                    <img src="assets/images/mail.png" className="img-fluid" alt="mail" />                
                    <Link to="mailto:aglileseekers@gmail.com">aglileseekers@gmail.com</Link>
                </Col>
                <Col md={4} sm={4} className='social'>
                    <Link to="/"><img src="assets/images/facebook.png" className="img-fluid" alt="facebook" /></Link>
                    <Link to="/"><img src="assets/images/instagram.png" className="img-fluid" alt="instagram" /></Link>
                    <Link to="/"><img src="assets/images/linkedin.png" className="img-fluid" alt="linkedin" /></Link>
                    <Link to="/"><img src="assets/images/twitter.png" className="img-fluid" alt="twitter" /></Link>
                </Col>
            </Row>
        </Container>
    </section>

    <section className='discountbar'>
        <Container>
            <Row>
                <Col className='discountbox'>
                    <p>Discount up to 70%</p>
                    <Link onClick={handleShow}><p>Grab Now</p></Link>
                </Col>
            </Row>
        </Container>
    </section>
    
    <Navbar collapseOnSelect expand="lg" id="agnav" className="bg-body-tertiary">
        <Container>
            <Navbar.Brand href="/"><img src="assets/images/logo.png" className="img-fluid" alt="logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <NavDropdown title="All Courses" id="collapsible-nav-dropdown">
                        <NavDropdown.Item href="/course-detail">Grow Personal Financial Security Thinking & Principles</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/">Corporate</Nav.Link>
                    <Nav.Link href="/resources">Resources</Nav.Link>
                    <Nav.Link href="/blogs">Blogs</Nav.Link>
                    <Nav.Link href="/contact-us">Contact Us</Nav.Link>                                        
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>

    <Modal size="lg" show={show} onHide={handleClose} centered className='disc-modal' backdrop="static">
        <Modal.Body>
            <Row className='mx-0'>
                <Col md={6} className='di-mod-left'>                    
                    <Image src="assets/images/special-offer.png" className='img-fluid spof' alt='special-offer'/>
                    <Image src="assets/images/super-offer.png" className='img-fluid' alt='super-offer'/>
                </Col>
                <Col md={6} className='di-mod-right'>
                    <Link to="/" onClick={handleClose}><Image src="assets/images/close.png" className='img-fluid' alt='close'/></Link>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGridPassword">
                            <Form.Label>Please Fill in the details below</Form.Label>
                            <Form.Control type="text" placeholder="Enter Full Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Control type="email" placeholder="Enter Your Email Id" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridAddress2">
                            <Form.Control type="text" placeholder="Mobile Number" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Claim Offer
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>

    </>
  );
}

export default CollapsibleExample;