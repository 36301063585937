import Container from 'react-bootstrap/Container';
import {Row, Col} from 'react-bootstrap';
import { Link } from "react-router-dom";




function Footer() {
  return (

    <>
        <section className='footer-top'>
            <Container>
                <Row className='ft-top'>
                    <Col md={3} xs={6} className='ft-link'>
                        <h3>Company</h3>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/about-us" onClick={() => window.scrollTo(0, 0)}>About Us</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/" onClick={() => window.scrollTo(0, 0)}>Careers</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/customer-speak" onClick={() => window.scrollTo(0, 0)}>Customer Speak</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact Us</Link>
                        </Col> 
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/become-an-instructor" onClick={() => window.scrollTo(0, 0)}>Become An Instructor</Link>
                        </Col>                                                                                                
                    </Col>
                    <Col md={3} xs={6} className='ft-link'>
                        <h3>Offerings</h3>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Live Virtual (Online)</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Classroom Training</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Corporate Training</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Refer and Earn</Link>
                        </Col>                       
                    </Col>
                    <Col md={3} xs={12} className='ft-link'>
                        <h3>Legal</h3>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/terms-and-conditions" onClick={() => window.scrollTo(0, 0)}>Terms and Conditions</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Cancellation and Refund Policy</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Disclaimer</Link>
                        </Col>                       
                    </Col>
                    <Col md={3} xs={12}>
                        <Row className='ft-contact mx-0'>
                            <img src="assets/images/cnt-msg.png" className="img-fluid cnt-msg" alt="cnt-msg" />
                            <h3>Contact Us</h3>
                            <Col className='cnt-phone'>
                                <img src="assets/images/cnt-phone.png" className="img-fluid" alt="cnt-phone" />
                                <Link to="tel:+919143964396">+91 91 4396 4396</Link>
                            </Col>
                            <Col className='cnt-phone'>
                                <img src="assets/images/cnt-phone.png" className="img-fluid" alt="cnt-phone" />
                                <Link to="tel:+918647992999">+91 86 4799 2999</Link>
                            </Col>
                            <Col className='cnt-mail'>
                                <img src="assets/images/cnt-mail.png" className="img-fluid" alt="cnt-mail" />
                                <Link to="mailto:info@agileseekers.com">info@agileseekers.com</Link>
                            </Col>   
                        </Row>                                           
                    </Col>                    
                </Row>
                <Row className='ft-mid'>
                    <Col md={3} xs={12} className='ft-link'>
                        <h3>Kanban</h3>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Kanban System Design (KMP1)</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Kanban Systems Improvement (KMP2)</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Team Kanban Practitioner (TKP)</Link>
                        </Col>                                                                      
                    </Col>
                    <Col md={3} xs={12} className='ft-link'>
                        <h3>SAFe</h3>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Leading SAFe</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">SAFe ScrumMaster</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Advanced ScrumMaster</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">SAFe ProductOwner</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">SAFe for Teams</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Lean Portfolio Management</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">Release Train Engineer (Private Classes only)</Link>
                        </Col>                                                                     
                    </Col>
                    <Col md={3} xs={6} className='ft-link'>
                        <h3>Agile Management</h3>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">ICP-ACC</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">CSM</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">CSPO</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">PSM</Link>
                        </Col>
                        <Col>
                            <img src="assets/images/ftarrow.png" className="img-fluid" alt="ftarrow" />
                            <Link to="/">PSPO</Link>
                        </Col>                                            
                    </Col>
                    <Col md={3} xs={6} className='ft-connect'>
                        <Col>
                            <h3>Connect With us</h3>
                            <Col>
                                <Link to="/"><img src="assets/images/linkedino.png" className="img-fluid" alt="linkedin" /></Link>
                                <Link to="/"><img src="assets/images/facebooko.png" className="img-fluid" alt="facebook" /></Link>
                                <Link to="/"><img src="assets/images/instagramo.png" className="img-fluid" alt="instagram" /></Link>                                
                                <Link to="/"><img src="assets/images/twittero.png" className="img-fluid" alt="twitter" /></Link>                             
                            </Col>
                        </Col>
                        <Col>
                            <h3>We Accepts</h3>
                            <img src="assets/images/we-accept.png" className="img-fluid we-accept" alt="we-accept" />
                        </Col>
                    </Col>
                </Row>
                <Row className='ft-bottom'>
                    <Col md={12} >
                        <p>2024 Copyright Agile Seekers. All Rights Reserved </p>
                    </Col>
                </Row>                                
            </Container>
        </section>
        <section className='footer-bottom'>
            <Container>
                <Row>
                    <Col md={3} className='fb-left'>
                        <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                            <p>Drop a Query</p>
                        </Link>
                    </Col>
                    <Col md={6} className='fb-center mx-0'>
                        <Col className='mail'>
                            <img src="assets/images/mail.png" className="img-fluid" alt="mail" />                
                            <Link to="mailto:aglileseekers@gmail.com">aglileseekers@gmail.com</Link>                        
                        </Col>
                        <Col className='mail'>
                            <img src="assets/images/phone.png" className="img-fluid" alt="phone" />                
                            <Link to="tel:+918647992999">+91 86 4799 2999</Link>                        
                        </Col>                        
                    </Col>
                    <Col md={3} className='social'>
                        <Link to="/"><img src="assets/images/facebook.png" className="img-fluid" alt="facebook" /></Link>
                        <Link to="/"><img src="assets/images/instagram.png" className="img-fluid" alt="instagram" /></Link>
                        <Link to="/"><img src="assets/images/linkedin.png" className="img-fluid" alt="linkedin" /></Link>
                        <Link to="/"><img src="assets/images/twitter.png" className="img-fluid" alt="twitter" /></Link>                    
                    </Col>
                </Row>
            </Container>
        </section>

    </>
  );
}

export default Footer;