import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Image, Form, Button} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const Resources = () => {

    useEffect(() => {
          document.title = `Resources | Agile Seekers`;
          document.getElementById('bodywrap').className='resomain';
    },[]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Navbar />

                <section className='resowrap'>
                    <Container>

                        <Row className='rs-filter'>
                            <Col md={3} className='rs-box'>
                                <h3>Resources</h3>
                                <Col className='frbox'>
                                    <Row>
                                        <Col className='frtitle px-0'>
                                            <Image src="assets/images/filter.png" className='img-fluid' alt='filter'/>      
                                            <p>Filter Resources</p>                                      
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className='px-0'>
        
                                            <div className="cusradio">
                                                <Form>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                        <Form.Check
                                                            label="Articles"
                                                            name="group1"
                                                            type={type}
                                                            id={`inline-${type}-1`}
                                                        />
                                                        <Form.Check
                                                            label="Videos"
                                                            name="group1"
                                                            type={type}
                                                            id={`inline-${type}-2`}
                                                        />
                                                        <Form.Check
                                                            label="Tools"
                                                            type={type}
                                                            id={`inline-${type}-3`}
                                                        />
                                                        <Form.Check
                                                            label="Templates"
                                                            type={type}
                                                            id={`inline-${type}-4`}
                                                        />                                                    
                                                        </div>
                                                    ))}
                                                    <Button variant="primary" type="submit">
                                                        Apply
                                                    </Button>                                                    
                                                </Form>
                                            </div>  

                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                            <Col md={9} className='rs-ebook'>
                                <h3>E Books</h3>
                                <Row>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb1.png" className='img-fluid' alt='eb1'/>
                                    </Col>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb2.png" className='img-fluid' alt='eb2'/>
                                    </Col>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb3.png" className='img-fluid' alt='eb3'/>
                                    </Col>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb4.png" className='img-fluid' alt='eb4'/>
                                    </Col>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb2.png" className='img-fluid' alt='eb2'/>
                                    </Col>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb3.png" className='img-fluid' alt='eb3'/>
                                    </Col>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb4.png" className='img-fluid' alt='eb4'/>
                                    </Col>
                                    <Col sm={6} md={4} lg={3} className='ebsingle'>
                                        <Col>
                                            <Col>
                                                <h3>Book name</h3>
                                                <p>Book name</p>
                                            </Col>
                                            <Link to="" onClick={handleShow}><Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </Col>
                                        <Image src="assets/images/eb1.png" className='img-fluid' alt='eb1'/>
                                    </Col>                                                                                                                                                                                                                                                            
                                </Row>
                            </Col>
                        </Row>

                        <Row className='res-accordian'>
                            <Col>
                                <h3>Our Practice Test Courses</h3>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header> Test 1</Accordion.Header>
                                        <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Test 2</Accordion.Header>
                                        <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Test 3</Accordion.Header>
                                        <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Test 4</Accordion.Header>
                                        <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        </Accordion.Body>
                                    </Accordion.Item>                                                                        
                                </Accordion>

                            </Col>
                        </Row>

                        <Row className='rrbox'>
                            <Col md={12}>
                                <h3>Related Resources</h3>
                            </Col>
                            <Col md={3} className='rrsingle'>
                                <div>
                                    <Image src="assets/images/rr1.png" className='img-fluid' alt='rr1'/>
                                    <h3>CISSP vs Security+ Certifications: Which is Best in 2024?</h3>
                                    <p>When it comes to top cybersecurity certifications, CISSP and Security+ stan....</p>
                                    <Link to="/course-detail">Read more</Link>
                                </div>
                            </Col>
                            <Col md={3} className='rrsingle'>
                                <div>
                                    <Image src="assets/images/rr2.png" className='img-fluid' alt='rr2'/>
                                    <h3>30 PMP Formulas You Must Know to Pass the PMP Certification Exam</h3>
                                    <p>One question frequently comes to me when I talk to students preparing for th...</p>
                                    <Link to="/course-detail">Read more</Link>
                                </div>
                            </Col>
                            <Col md={3} className='rrsingle'>
                                <div>
                                    <Image src="assets/images/rr3.png" className='img-fluid' alt='rr3'/>
                                    <h3>Pass AWS Certification Exams with benefits of AWS Whitepapers</h3>
                                    <p>A vital component of the IT industry in today's world is Amazon Web Services...</p>
                                    <Link to="/course-detail">Read more</Link>
                                </div>
                            </Col>
                            <Col md={3} className='rrsingle'>
                                <div>
                                    <Image src="assets/images/rr4.png" className='img-fluid' alt='rr4'/>
                                    <h3>30 PMP Formulas You Must Know to Pass the PMP Certification Exam</h3>
                                    <p>One question frequently comes to me when I talk to students preparing for th...</p>
                                    <Link to="/course-detail">Read more</Link>
                                </div>
                            </Col>                                                                                    
                        </Row>

                    </Container>
                </section>



                <Modal show={show} onHide={handleClose} centered className='ebook-popup' backdrop="static">
                    <Modal.Body>
                        <Col>
                            <h3>Request Resources</h3>
                            <Form>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="fname">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter First Name" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="lname">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Last Name" />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter your email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your contact number" />
                                </Form.Group>
                                <Button variant="primary" type="submit" onClick={handleClose}>
                                    Submit
                                </Button>
                            </Form>                            
                        </Col>
                    </Modal.Body>
                </Modal>

            <Footer />

        </>
    )        

}
    export default Resources;    