import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import About from './Pages/About/About';
import Blog from './Pages/Blog/Blog';
import BlogDetail from './Pages/BlogDetail/BlogDetail';
import Contact from './Pages/Contact/Contact';
import CourseDetail from './Pages/CourseDetail/CourseDetail';
import CustomerSpeak from './Pages/CustomerSpeak/CustomerSpeak';
import Home from './Pages/Home/Home';
import Instructor from './Pages/Instructor/Instructor';
import Privacy from './Pages/Privacy/Privacy';
import Resources from './Pages/Resources/Resources';
import Terms from './Pages/Terms/Terms';
import ViewSchedule from './Pages/ViewSchedule/ViewSchedule';


import Demo from './Pages/Demo/Demo';
import Test from './Pages/Test/Test';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/blogs" element={<Blog />} />
          <Route exact path="/blog-detail" element={<BlogDetail />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/course-detail" element={<CourseDetail />} />
          <Route exact path="/customer-speak" element={<CustomerSpeak />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/become-an-instructor" element={<Instructor />} />          
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route exact path="/resources" element={<Resources />} />          
          <Route exact path="/terms-and-conditions" element={<Terms />} />
          <Route exact path="/view-schedule" element={<ViewSchedule />} />

          <Route exact path="/demo" element={<Demo/>} />
          <Route exact path="/test" element={<Test/>} />
        </Routes>
      </Router>                
    </>
  );
}

export default App;