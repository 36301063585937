import React, { useEffect } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

const Blog = () => {

    useEffect(() => {
          document.title = `Blog | Agile Seekers`;
          document.getElementById('bodywrap').className='blogmain';
    },[]);




    return (
        <>
            <Navbar />

                <section className='blog-top-sec'>
                    <Container>

                        <Row>
                            <Col md={9}>
                                <div className='btbox'>
                                    <Image src="assets/images/blog-banner.png" className='img-fluid' alt='blog-banner'/>
                                    <div>
                                        <Image src="assets/images/blclk.png" className='img-fluid' alt='blclk'/>
                                        <p>Published on 28th Sep, 2024</p>
                                    </div>
                                    <h3>PMP Exam Pass Rate in 2024: Percentage of Passing PMP Exam</h3>
                                    <h6>The Project Management Professional (PMP) exam is offered by the Project Management Institute (PMI) in the USA (United States of America). It is the w <Link to="/blog-detail">Read More</Link></h6>
                                </div>
                            </Col>
                            <Col md={3}>
                                <Row className='tpbox'>
                                    <Col md={12}>
                                        <h3>Trending Posts</h3>
                                    </Col>
                                    <Col md={12} className='tpsingle'>
                                        <div>
                                            <h3>What Are the SAFe Agile Principles</h3>
                                            <p>Published on 19 Feb 2024</p>
                                            <Link to="/blog-detail">Agile</Link>
                                        </div>
                                    </Col>
                                    <Col md={12} className='tpsingle'>
                                        <div>
                                            <h3>Differences Between Certified SAFE® Agilist(SA) vs Certified SAFe Practitioner(SP)</h3>
                                            <p>Published on 10 Sep 2024</p>
                                            <Link to="/blog-detail">Project Management</Link>
                                        </div>
                                    </Col>
                                    <Col md={12} className='tpsingle'>
                                        <div>
                                            <h3>How to Install Jenkins on Ubuntu 22.04? [Step-by- Step Guide]</h3>
                                            <p>Published on 01 Jul 2024</p>
                                            <Link to="/blog-detail">Dev OPs</Link>
                                        </div>
                                    </Col>  
                                    <Col className='wfu-btn'>
                                        <Link to="/">Write for us <Image src="assets/images/arrow-white.png" className='img-fluid' alt='arrow-white'/></Link>                                        
                                    </Col>                                                                               
                                </Row>                            
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <h3 className='topictitle'>Topics</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} xs={6}>
                                <div className='topicbox'>
                                    <Image src="assets/images/topic1.png" className='img-fluid' alt='topic1'/>
                                    <p>Blogs</p>
                                </div>
                            </Col>
                            <Col md={3} xs={6}>
                                <div className='topicbox'>
                                    <Image src="assets/images/topic2.png" className='img-fluid' alt='topic2'/>
                                    <p>Infographics</p>
                                </div>
                            </Col>
                            <Col md={3} xs={6}>
                                <div className='topicbox'>
                                    <Image src="assets/images/topic3.png" className='img-fluid' alt='topic3'/>
                                    <p>News</p>
                                </div>
                            </Col>
                            <Col md={3} xs={6}>
                                <div className='topicbox'>
                                    <Image src="assets/images/topic4.png" className='img-fluid' alt='topic4'/>
                                    <p>Announcement</p>
                                </div>
                            </Col>                                                                                    
                        </Row>
                    </Container>
                </section>

                <section className='blog-bot-sec'>
                    <Container>
                        <Row className='lpbox'>
                            <Col md={12}>
                                <h3>Latest Posts</h3>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr1.png" className='img-fluid' alt='rr1'/>
                                    <h3>CISSP vs Security+ Certifications: Which is Best in 2024?</h3>
                                    <p>When it comes to top cybersecurity certifications, CISSP and Security+ stan....</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Abhresh Sugandhi</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr2.png" className='img-fluid' alt='rr2'/>
                                    <h3>30 PMP Formulas You Must Know to Pass the PMP Certification Exam</h3>
                                    <p>One question frequently comes to me when I talk to students preparing for th...</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Kevin D.Davis</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>                                    
                                </div>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr3.png" className='img-fluid' alt='rr3'/>
                                    <h3>Pass AWS Certification Exams with benefits of AWS Whitepapers</h3>
                                    <p>A vital component of the IT industry in today's world is Amazon Web Services...</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Joydip Kumar</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>                                    
                                </div>
                            </Col>
                            <Col md={3} className='lpsingle'>
                                <div>
                                    <Image src="assets/images/rr4.png" className='img-fluid' alt='rr4'/>
                                    <h3>30 PMP Formulas You Must Know to Pass the PMP Certification Exam</h3>
                                    <p>One question frequently comes to me when I talk to students preparing for th...</p>
                                    <Link to="/blog-detail">Agile</Link>
                                    <div>
                                        <p>By <span>Kevin D.Davis</span></p>
                                        <p>09 Oct 2024</p>
                                    </div>                                    
                                </div>
                            </Col>                                                                                    
                        </Row>
                        <Row>
                            <Col md={12} className='lpvm-btn'>
                                <Link to="/">View More</Link>
                            </Col>
                        </Row>
                    </Container>
                </section>

            <Footer />

        </>
    )        

}
    export default Blog;    