import React, { useEffect } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

const About = () => {

    useEffect(() => {
          document.title = `About Us | Agile Seekers`;
          document.getElementById('bodywrap').className='aboutmain';
    },[]);




    return (
        <>
            <Navbar />

            <section className='about-banner'>
                <Container fluid>
                    <Row>
                        <Col className='px-0 abimg'>
                            <h2>about us</h2>
                            <Image src="assets/images/about.jpg" className='img-fluid' alt='about'/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='about-bottom'>
                <Container>
                    <Row className='about-top'>
                        <Col md={12}>
                            <h3>Overview</h3>
                            <p>KnowledgeHut is a leading training provider, helping professionals across industries and sectors develop new expertise and bridge their skill gap for recognition and growth in the global corporate world.Developed with the intention of delivering high value training through innovative and practical approaches, KnowledgeHut offers a wide range of services in training, learning and development in the fields of technology and management.</p>
                            <p>The founders of the company are zealous young entrepreneurs, who were motivated by the need to fill a niche in the IT Training industry for professionals and they are aided in their goal by industry experts who conduct the workshops; igniting minds and motivating professionals to face on-the-job challenges.</p>
                        </Col>
                        <Col md={12}>
                            <h3>Our Corporate training mantra</h3>
                            <p>To stay abreast with the ever changing technologies in this industry, and also to help continued professional development among aspiring IT managers and executives, KnowledgeHut offers a variety of accredited training programs.</p>
                            <p>Our corporate trainings have been designed with this idea in mind and we offer you customized solutions for all your training needs. KnowledgeHut is an extended solutions partner to organizations seeking consulting or training services to enhance workforce performance and drive business success. We have successfully helped innumerous corporates bridge the knowledge and skill gap.</p>
                        </Col>                        
                    </Row>
                    <Row className='about-grid'>
                        <Col md={6}>
                            <h3>What we offer</h3>
                            <Image src="assets/images/line.png" className='img-fluid ab-line' alt='line'/>
                            <p>KnowledgeHut is a leading training provider, helping professionals across industries and sectors develop new expertise and bridge their skill gap for recognition and growth in the global corporate world.Developed with the intention of delivering high value training through innovative and practical approaches, KnowledgeHut offers a wide range of services in training, learning and development in the fields of technology and management.</p>
                            <p>Our courseware in the fields of Sales, Operations, Marketing, Management and Consulting is aimed at delivering measurable improvements in business performances and productivity. Professionals who have completed our training are equipped with state-of-the-art knowledge and technical skills that enable them to stay at the forefront of their careers.</p>
                        </Col>
                        <Col md={6}>
                            <Image src="assets/images/wwo.png" className='img-fluid' alt='wwo'/>
                        </Col>
                    </Row>
                    <Row className='about-grid'>
                        <Col md={6} className='ord2'>
                            <Image src="assets/images/hwd.png" className='img-fluid' alt='hwd'/>
                        </Col>
                        <Col md={6}>
                            <h3>How we do it</h3>
                            <Image src="assets/images/line.png" className='img-fluid ab-line' alt='line'/>
                            <p>Our courses are delivered through various modes - classroom workshops, e-learning courses, virtual classrooms as well as blended learning. By leveraging our training capabilities multiple times, thousands of satisfied employees and organizations have discovered new ways to strengthen and run their businesses. We offer custom-created training that can be delivered at your premises.</p>
                            <p>KnowledgeHut works with the Global Blended Model of Approach (GBMA), an effective method that makes training and learning easier for individuals and organizations in the industry. This methodology works through a blend of extensive online training in tandem with traditional classroom based learning. Participants of KnowledgeHut training courses have consistently achieved positive training results, with a shorter learning curve and significantly higher retention of the subject matter.</p>
                        </Col>
                    </Row>                    
                </Container>
            </section>


            <Footer />

        </>
    )        

}
    export default About;    