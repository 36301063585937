import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Link } from "react-router-dom";


const Test = () => {

    useEffect(() => {
          document.title = `Test | Agile Seekers`;
          document.getElementById('bodywrap').className='testmain';
    },[]);

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Link to="/home">Test</Link>
                    </Col>
                </Row>
            </Container>
        </>
    )        

}
    export default Test;  