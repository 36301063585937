import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Image, Form, Button} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Accordion from 'react-bootstrap/Accordion';

const CourseDetail = () => {

    useEffect(() => {
          document.title = `Course Detail | Agile Seekers`;
          document.getElementById('bodywrap').className='coudetmain';
    },[]);


    const settings1 = {

        speed: 2000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false        

        };

        const handleClickScroll1 = () => {
            const element1 = document.getElementById('ccb1');
            if (element1) {
              element1.scrollIntoView({ behavior: 'smooth' });
            }
          };
          const handleClickScroll2 = () => {
            const element2 = document.getElementById('ccb2');
            if (element2) {
              element2.scrollIntoView({ behavior: 'smooth' });
            }
          };
          const handleClickScroll3 = () => {
            const element3 = document.getElementById('ccb3');
            if (element3) {
              element3.scrollIntoView({ behavior: 'smooth' });
            }
          };
          const handleClickScroll4 = () => {
            const element4 = document.getElementById('ccb4');
            if (element4) {
              element4.scrollIntoView({ behavior: 'smooth' });
            }
          };
          const handleClickScroll5 = () => {
            const element5 = document.getElementById('ccb5');
            if (element5) {
              element5.scrollIntoView({ behavior: 'smooth' });
            }
          };
          const handleClickScroll6 = () => {
            const element6 = document.getElementById('ccb6');
            if (element6) {
              element6.scrollIntoView({ behavior: 'smooth' });
            }
          };
          const handleClickScroll7 = () => {
            const element7 = document.getElementById('ccb7');
            if (element7) {
              element7.scrollIntoView({ behavior: 'smooth' });
            }
          };
          const handleClickScroll8 = () => {
            const element8 = document.getElementById('ccb8');
            if (element8) {
              element8.scrollIntoView({ behavior: 'smooth' });
            }
          };                              
          
          const [isLeadingButtonVisible, setIsLeadingButtonVisible] = useState(true);
          const [hasLeadingClass, setHasLeadingClass] = useState(true);
    
          const handleLeadingClick = () => {
            setIsLeadingButtonVisible(false); 
            setHasLeadingClass(false); 
          };  

          const [isAgileButtonVisible, setIsAgileButtonVisible] = useState(true);
          const [hasAgileClass, setHasAgileClass] = useState(true);
    
          const handleAgileClick = () => {
            setIsAgileButtonVisible(false); 
            setHasAgileClass(false); 
          };         
          
          const [isPrereqButtonVisible, setIsPrereqButtonVisible] = useState(true);
          const [hasPrereqClass, setHasPrereqClass] = useState(true);
    
          const handlePrereqClick = () => {
            setIsPrereqButtonVisible(false); 
            setHasPrereqClass(false); 
          };     
          
          const [isFaqButtonVisible, setIsFaqButtonVisible] = useState(true);
          const [hasFaqClass, setHasFaqClass] = useState(true);
    
          const handleFaqClick = () => {
            setIsFaqButtonVisible(false); 
            setHasFaqClass(false); 
          }; 
          
          const [isLearnButtonVisible, setIsLearnButtonVisible] = useState(true);
          const [hasLearnClass, setHasLearnClass] = useState(true);
    
          const handleLearnClick = () => {
            setIsLearnButtonVisible(false); 
            setHasLearnClass(false); 
          };           

    return (
        <>
            <Navbar />

                <section className='cd-top-sec'>
                    <Container>
                        <Row className='cd-breadcrumb'>
                            <Col>
                                <Link to='/'>Home</Link>
                                <Image src="assets/images/vs-back.png" className='img-fluid' alt='vs-back'/>
                                <Link to='/'>ChatGTP & AI</Link>
                                <Image src="assets/images/vs-back.png" className='img-fluid' alt='vs-back'/>
                                <p>Leading SAFe® 6.0 Training with SAFe® Agilist Certification</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7} lg={8}>
                                <div className='le-pink'>
                                    <div>
                                        <h5>41,500+</h5>
                                        <h6>Learners Enrolled</h6>                                        
                                    </div>
                                </div>
                                <h2 className='cd-maintitle'>Leading SAFe® 6.0 Training with SAFe® Agilist Certification</h2> 
                                <div className='cd-tick-box'>
                                    <Image src="assets/images/tick.png" className='img-fluid' alt='tick'/>
                                    <p>Basic understanding of Agile, Lean & Kanban</p>
                                </div>
                                <div className='cd-tick-box'>
                                    <Image src="assets/images/tick.png" className='img-fluid' alt='tick'/>
                                    <p>Understand the Visualization of tasks, risks etc. using different types of Kanban Boards</p>
                                </div>                                
                                <div className='cd-tick-box'>
                                    <Image src="assets/images/tick.png" className='img-fluid' alt='tick'/>
                                    <p>Implementation of Team Kanban Task Board for your team</p>
                                </div>                                
                                <div className='cd-tick-box'>
                                    <Image src="assets/images/tick.png" className='img-fluid' alt='tick'/>
                                    <p>Qualifies you for the globally accepted Team Kanban Practitioner® (TKP®) credential from the Lean Kanban University</p>
                                </div>                                                                                                                                                                                                
                                <div className='cd-top-btn'>
                                    <Link to="/" className='ds-btn'>Download Syllabus</Link>
                                    <Link to="/view-schedule" className='vs-btn'>View Schedule</Link>
                                </div>                               
                            </Col>
                            <Col md={5} lg={4}>
                                <div className='cd-banner-social'>
                                    <div>
                                        <div>
                                            <Image src="assets/images/cu-google.svg" className='img-fluid' alt='cu-google'/>
                                            <p>4.8/4</p>
                                        </div>
                                        <div>
                                            <Image src="assets/images/cu-facebook.svg" className='img-fluid' alt='cu-facebook'/>
                                            <p>4.7/5</p>
                                        </div>
                                        <div>
                                            <Image src="assets/images/cu-reload.svg" className='img-fluid' alt='cu-reload'/>
                                            <p>4.7/5</p>
                                        </div>                                                                                    
                                    </div>
                                    <Image src="assets/images/cd-banner.png" className='img-fluid' alt='cd-banner'/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='cu-top-review'>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <div className='google-review'>
                                    <div>
                                        <Image src="assets/images/google.png" className='img-fluid' alt='google'/>
                                    </div>                                    
                                    <div>
                                        <Image src="assets/images/orange-star.svg" className='img-fluid star-img' alt='orange-star'/>
                                        <p>(5/5)</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='verified-review'>
                                    <div>
                                        <Image src="assets/images/vrsvg.svg" className='img-fluid' alt='vrsvg'/>
                                    </div>                                    
                                    <div>
                                        <h3>Verified Reviews</h3>
                                        <div>                                        
                                            <Image src="assets/images/yellow-star.svg" className='img-fluid star-img' alt='yellow-star'/>
                                            <p>(5/5)</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='trustpilot-review'>
                                    <div>
                                        <Image src="assets/images/vrtrust.svg" className='img-fluid' alt='vrtrust'/>
                                    </div>                                    
                                    <div>
                                        <Image src="assets/images/green-star.svg" className='img-fluid star-img' alt='green-star'/>
                                        <p>(5/5)</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='cu-top-tab d-none'>
                    <Container>
                        <Row>
                            <Col>
                                <Tabs
                                    defaultActiveKey="overview"
                                    id="cu-top-tab"
                                    className="mb-3"
                                    >
                                    <Tab eventKey="overview" title="Overview">
                                        <Row>
                                            <Col md={8}>
                                                <h2>SAFe Training Key Features</h2>
                                                <Row>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>2 Days of Online Virtual Class</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Access to Online Learning</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>One-year certified membership as SAFe® Agilist</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Live Learning & Instructor Assistance</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Learn to scale Agile practices across multiple teams</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Learn to scale Agile practices across multiple teams</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Delivered by SPC (SAFe Program Consultants)</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Finishing the Program Increment</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Experiencing Program Increment planning</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Certified SAFe® Scrum Master PDF certificate</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Facilitating Iteration execution</p>                                                    
                                                    </Col>
                                                    <Col md={6} className='cttli'>
                                                        <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                        <p>Program Managers /Projects Managers</p>                                                    
                                                    </Col> 
                                                    <Col md={12}>
                                                        <Link className='mbg-btn' to="/"><Image src="assets/images/mbg.svg" className='img-fluid' alt='shiled'/> 100% Money Back Guarantee</Link>
                                                    </Col>                                                                                                                                                                                                               
                                                </Row>
                                            </Col>
                                            <Col md={4} className='over-certi'>
                                                <div>
                                                    <Image src="assets/images/certificate.png" className='img-fluid' alt='certificate'/>
                                                    <Link to="/">Sharable on <Image src="assets/images/linkedin-logo.png" className='img-fluid' alt='linkedin-logo'/> </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="course-curriculum" title="Course Curriculum">
                                        Tab content for Course Curriculum
                                    </Tab>
                                    <Tab eventKey="course-fee" title="Course Fee">
                                        Tab content for Course Fee
                                    </Tab>
                                    <Tab eventKey="about-certi" title="About Certification">
                                        Tab content for About Certification
                                    </Tab>
                                    <Tab eventKey="learning-objectives" title="Learning Objectives">
                                        Tab content for Learning Objectives
                                    </Tab>
                                    <Tab eventKey="prerequisites" title="prerequisites">
                                        Tab content for Prerequisites
                                    </Tab>
                                    <Tab eventKey="enroll-now" title="Enroll Now">
                                        Tab content for Enroll Now
                                    </Tab>
                                    <Tab eventKey="phone-number" title="Phone Number">
                                        Tab content for Phone Number
                                    </Tab>                                                                                                                                                                                    
                                </Tabs>                            
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='cousrse-content-box'>
                    <Container>
                        <Row>
                            <Col md={12} className='course-top-tab'>
                                <div>
                                    <Link onClick={handleClickScroll1}>Overview</Link>
                                    <Link onClick={handleClickScroll2}>Course Curriculum</Link>
                                    <Link onClick={handleClickScroll3}>Course Fee</Link>
                                    <Link onClick={handleClickScroll4}>About Certification</Link>
                                    <Link onClick={handleClickScroll5}>Learning Objectives</Link>
                                    <Link onClick={handleClickScroll6}>Prerequisites</Link>
                                    <Link onClick={handleClickScroll7}>Phone Number</Link>
                                    <Link onClick={handleClickScroll8}>Enroll Now</Link>
                                </div>
                            </Col>

                            <Col md={8} lg={8}>

                                <Row className='course-top-box' id="ccb1">
                                    <Col md={12}>
                                        <h3>SAFe Training Key Features</h3>
                                        <Row>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>2 Days of Online Virtual Class</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Access to Online Learning</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>One-year certified membership as SAFe® Agilist</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Live Learning & Instructor Assistance</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Learn to scale Agile practices across multiple teams</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Learn to scale Agile practices across multiple teams</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Delivered by SPC (SAFe Program Consultants)</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Finishing the Program Increment</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Experiencing Program Increment planning</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Certified SAFe® Scrum Master PDF certificate</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Facilitating Iteration execution</p>                                                    
                                            </Col>
                                            <Col md={6} className='cttli'>
                                                <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                <p>Program Managers /Projects Managers</p>                                                    
                                            </Col> 
                                            <Col md={12}>
                                                <Link className='mbg-btn' to="/"><Image src="assets/images/mbg.svg" className='img-fluid' alt='shiled'/> 100% Money Back Guarantee</Link>
                                            </Col>                                                                                                                                                                                                               
                                        </Row>
                                    </Col>                                    
                                </Row>

                                <Row className='cousrse-gen-accordian'  id="ccb2">
                                    <Col md={12}>
                                        <div className='cga-top'>
                                            <h3>Leading safe certification course curriculum</h3>
                                            <Link to='/'>Download Curriculum <Image src="assets/images/download.png" className='img-fluid' alt='download'/></Link>
                                        </div>
                                        <div className={hasLeadingClass ? "leading-box cga-acc-box" : "cga-acc-box"}>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header> 1. Thriving in the Digital Age with Business Agility</Accordion.Header>
                                                    <Accordion.Body>
                                                        <h4>Learning Objectives</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>2. Building a Foundation with Mindset, Values, and Principles</Accordion.Header>
                                                    <Accordion.Body>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>3. Establishing Team and Technical Agility</Accordion.Header>
                                                    <Accordion.Body>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>4. Building Solutions with Agile Product Delivery</Accordion.Header>
                                                    <Accordion.Body>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>                                                                        
                                            </Accordion>
                                        </div>
                                        {isLeadingButtonVisible && (
                                        <div className='lpvm-btn'>
                                            <Link onClick={handleLeadingClick} >View More</Link>
                                        </div> 
                                         )}                                        
                                    </Col>
                                </Row>

                                <Row className='course-fee-main' id="ccb3">
                                    <h3>Course Fee</h3>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={12} lg={6} className='cf-box'>
                                                <div className='cfm-box'>
                                                    <div>
                                                        <h4>Live online classroom</h4>
                                                        <h5>Learn in Expert-Led Live Sessions</h5>                                                    
                                                        <h4>Solid Experiential Learning</h4>
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>Comprehensive Exam Support</p>                                                    
                                                        </div>   
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>16-Hour Live Instructor-Led Training</p>                                                    
                                                        </div>   
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>1-Year SAFe Community Membership</p>                                                    
                                                        </div>   
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>Exam Fee Included in Course Fee</p>                                                    
                                                        </div>
                                                        <h4>Upcoming Batches</h4>
                                                        <div className='ubbox'>
                                                            <h5>19 Sep: Weekday Batch</h5>                                                                                                                                                                                                                                                                    
                                                            <Link to="/">View all schedule</Link>
                                                        </div>                                                    
                                                    </div>
                                                    <div className='enroll-box'>
                                                        <div>
                                                            <h5>46% OFF</h5>
                                                            <h4>₹35,400 </h4>
                                                        </div>
                                                        <Link to="/">Enroll Now</Link>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='cf-box'>
                                                <div className='cfm-box'>
                                                    <div>
                                                        <h4>Enterprise Training</h4>
                                                        <h5>Upskill and Reskill Your Teams</h5> 
                                                        <h4>Customized Corporate Training</h4>
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>Unleash In-Demand Skills Across the Enterprise</p>                                                    
                                                        </div>   
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>Align Skill Development with Business Objectives</p>                                                    
                                                        </div>   
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>Drive Increased Employee Productivity</p>                                                    
                                                        </div>   
                                                        <div className='cttli'>
                                                            <Image src="assets/images/shiled.png" className='img-fluid' alt='shiled'/>
                                                            <p>Leverage Immersive Learning</p>                                                    
                                                        </div>                                                                                                                                                                                                                
                                                    </div>
                                                    <div className='sytbox'>
                                                        <Link to="/">Skill Up Your Teams</Link>
                                                    </div>
                                                </div>                                            
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className='cousrse-gen-accordian' id="ccb4">
                                    <Col md={12}>
                                        <div className='cga-top'>
                                            <h3>About Agile Seekers Certificates</h3>
                                        </div>                                        
                                        <div className={hasAgileClass ? "leading-box cga-acc-box" : "cga-acc-box"}>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header> 1. Thriving in the Digital Age with Business Agility</Accordion.Header>
                                                    <Accordion.Body>
                                                        <h4>Learning Objectives</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>2. Building a Foundation with Mindset, Values, and Principles</Accordion.Header>
                                                    <Accordion.Body>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>3. Establishing Team and Technical Agility</Accordion.Header>
                                                    <Accordion.Body>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>4. Building Solutions with Agile Product Delivery</Accordion.Header>
                                                    <Accordion.Body>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>                                                                        
                                            </Accordion>
                                        </div>       
                                        {isAgileButtonVisible && (
                                        <div className='lpvm-btn'>
                                            <Link onClick={handleAgileClick} >View More</Link>
                                        </div> 
                                         )}                                                                          
                                    </Col>
                                </Row>

                                <Row className='wl-main-box' id="ccb5">
                                    <Col>
                                        
                                        <Row className={hasLearnClass ? "learn-box" : ""}>
                                            <Col md={12}>
                                                <h3>What you will learn</h3>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Learn fundamentals</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>01</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Effective scaling</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>02</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Lean-Agile principles</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>03</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Maximize value</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>04</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Lean Portfolio</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>05</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Seven core competencies</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>06</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Leadership Skills</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>07</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Lead Transformation</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>08</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Lean Portfolio Management</h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>09</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={6} className='wlmain'>
                                                <div className='wlbox'>
                                                    <div className='wlcont'>
                                                        <h4>Our Training </h4>
                                                        <p>Get introduced to the fundamental concepts of Business Agility and decipher the major competencies of Business Agility</p>
                                                    </div>
                                                    <div className='polygon'>
                                                        <p>10</p>
                                                        <Image src="assets/images/polygon.png" className='img-fluid' alt='polygon'/>
                                                    </div>
                                                </div>
                                            </Col>                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                        </Row>
                                        {isLearnButtonVisible && (
                                            <div className='lpvm-btn mb-0'>
                                                <Link onClick={handleLearnClick} >View More</Link>
                                            </div> 
                                        )}                                                                                    
                                    </Col>
                                </Row>

                                <Row className='cousrse-tab-accordian' id="ccb6">
                                    <Col md={12} className='cta-top'>
                                        <h3>Prerequisites and Eligibility</h3>
                                    </Col>
                                    <Col md={12}>
                                        <Tab.Container id="peaccordian" defaultActiveKey="prerequisites">
                                            <Row>
                                                <Col sm={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="prerequisites">Prerequisites</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="projects">Projects</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="success-stories">Success Stories</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="course-fee">Course Fee</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="admissions">Admissions</Nav.Link>
                                                    </Nav.Item>                                                                                                                                                            
                                                </Nav>
                                                </Col>
                                                <Col sm={9}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="prerequisites">                                                        
                                                        <div className={hasPrereqClass ? "leading-box cta-acc-box" : "cta-acc-box"}>                                                            
                                                            <Accordion defaultActiveKey="0">
                                                                <Accordion.Item eventKey="0">
                                                                    <Accordion.Header> What is upGrad's free courses initiative all about? </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <p>What the world is experiencing today is a truly unprecedented scenario. We are all plagued with absolute uncertainty. How things pan out, only time will tell, but how we utilize our time right now can make a world of a difference to our future. upGrad’s Free Courses goal is to help you pick up on some of the hottest skills that are trending in the market today in a few hours, absolutely free of cost.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="1">
                                                                    <Accordion.Header>Will I have to pay anything to start learning?</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="2">
                                                                    <Accordion.Header>Who should sign up?y</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="3">
                                                                    <Accordion.Header>Will I get a certificate?</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>  
                                                                <Accordion.Item eventKey="4">
                                                                    <Accordion.Header>What is the duration for which I can access to the content?</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>                                                                                                                                        
                                                            </Accordion>
                                                        </div> 
                                                        {isPrereqButtonVisible && (
                                                            <div className='lpvm-btn'>
                                                                <Link onClick={handlePrereqClick} >View More</Link>
                                                            </div> 
                                                        )}                                                                                                                 
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="projects">Projects</Tab.Pane>
                                                    <Tab.Pane eventKey="success-stories">Success Stories</Tab.Pane>
                                                    <Tab.Pane eventKey="course-fee">Course Fee</Tab.Pane>
                                                    <Tab.Pane eventKey="admissions">Admissions</Tab.Pane>
                                                </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>                                    
                                    </Col>
                                </Row>

                                <Row className='course-phone' id="ccb7">
                                    <Col>
                                        <div className='course-ph-box'>
                                            <Row>
                                                <Col md={12} lg={6}>
                                                    <Row className='cpb-wrap mx-0'>
                                                        <Col md={6}>
                                                            <Image src="assets/images/india-circle.svg" className='img-fluid' alt='india-circle'/>
                                                            <p>Indian Nationals</p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Image src="assets/images/call-yl.svg" className='img-fluid' alt='call-yl'/>                                                        
                                                            <Link to="tel:1231231234">123 123 1234</Link>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} lg={6}>
                                                    <Row className='cpb-wrap mx-0'>
                                                        <Col md={6}>
                                                            <Image src="assets/images/globe.svg" className='img-fluid' alt='globe'/>
                                                            <p>Foreign Nationals</p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Image src="assets/images/call-yl.svg" className='img-fluid' alt='globe'/>                                                        
                                                            <Link to="tel:18002102020">1800 210 2020</Link>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='cousrse-tab-accordian' id="ccb8">
                                    <Col md={12} className='cta-top'>
                                        <h3>Frequently Asked Quesions</h3>
                                    </Col>
                                    <Col md={12}>
                                        <Tab.Container id="peaccordian" defaultActiveKey="leadership-skills">
                                            <Row>
                                                <Col sm={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="leadership-skills">Leadership Skills</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="free-courses">Free Courses</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="it-courses">IT Courses</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="timings">Timings</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link eventKey="placements">Placements</Nav.Link>
                                                    </Nav.Item>                                                                                                                                                            
                                                </Nav>
                                                </Col>
                                                <Col sm={9}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="leadership-skills">
                                                    <div className={hasFaqClass ? "leading-box cta-acc-box" : "cta-acc-box"}> 
                                                            <Accordion defaultActiveKey="0">
                                                                <Accordion.Item eventKey="0">
                                                                    <Accordion.Header> What is upGrad's free courses initiative all about? </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <p>What the world is experiencing today is a truly unprecedented scenario. We are all plagued with absolute uncertainty. How things pan out, only time will tell, but how we utilize our time right now can make a world of a difference to our future. upGrad’s Free Courses goal is to help you pick up on some of the hottest skills that are trending in the market today in a few hours, absolutely free of cost.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="1">
                                                                    <Accordion.Header>Will I have to pay anything to start learning?</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="2">
                                                                    <Accordion.Header>Who should sign up?y</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="3">
                                                                    <Accordion.Header>Will I get a certificate?</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>  
                                                                <Accordion.Item eventKey="4">
                                                                    <Accordion.Header>What is the duration for which I can access to the content?</Accordion.Header>
                                                                    <Accordion.Body>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>                                                                                                                                        
                                                            </Accordion>
                                                        </div>
                                                        {isFaqButtonVisible && (
                                                            <div className='lpvm-btn'>
                                                                <Link onClick={handleFaqClick} >View More</Link>
                                                            </div> 
                                                        )}                                                              
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="free-courses">Free Courses</Tab.Pane>
                                                    <Tab.Pane eventKey="it-courses">IT Courses</Tab.Pane>
                                                    <Tab.Pane eventKey="timings">Timings</Tab.Pane>
                                                    <Tab.Pane eventKey="placements">Placements</Tab.Pane>
                                                </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>                                    
                                    </Col>
                                </Row>                                

                                <Row className='tb-main'>
                                    <Col>
                                        <Row>
                                            <Col md={12}>
                                                <h3>Trusted By</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='tb-box'>
                                                <Slider {...settings1}>
                                                    <Image src="assets/images/tb1.png" className='img-fluid' alt='tb1'/>
                                                    <Image src="assets/images/tb2.png" className='img-fluid' alt='tb2'/>
                                                    <Image src="assets/images/tb3.png" className='img-fluid' alt='tb3'/>
                                                    <Image src="assets/images/tb4.png" className='img-fluid' alt='tb4'/>
                                                    <Image src="assets/images/tb5.png" className='img-fluid' alt='tb5'/>
                                                    <Image src="assets/images/tb6.png" className='img-fluid' alt='tb6'/>
                                                    <Image src="assets/images/tb7.png" className='img-fluid' alt='tb7'/>
                                                    <Image src="assets/images/tb8.png" className='img-fluid' alt='tb8'/>
                                                    <Image src="assets/images/tb9.png" className='img-fluid' alt='tb9'/>
                                                    <Image src="assets/images/tb10.png" className='img-fluid' alt='tb10'/>
                                                    <Image src="assets/images/tb11.png" className='img-fluid' alt='tb11'/>
                                                    <Image src="assets/images/tb12.png" className='img-fluid' alt='tb12'/>
                                                </Slider>
                                            </Col>                                   
                                        </Row>
                                    </Col>
                                </Row>   

                                <Row className='sdcg-main'>
                                    <Col md={12}>
                                        <h3>Soaring Demand & Career Growth</h3>
                                    </Col>
                                    <Col md={12}>
                                        <Tabs
                                            defaultActiveKey="project-manager"
                                            id="sdcg-tab"
                                            className="mb-3"
                                            >
                                            <Tab eventKey="project-manager" title="Project Manager">
                                                <Row>
                                                    <Col md={6} className='tab-col-left'>
                                                        <h4>Soaring Demand and Career Growth</h4>
                                                        <Image src="assets/images/chart1.png" className='img-fluid' alt='chart1'/>
                                                    </Col>
                                                    <Col md={6} className='tab-col-right'>
                                                        <h4>Target Audience</h4>
                                                        <Image src="assets/images/taimg.png" className='img-fluid' alt='taimg'/>
                                                    </Col>
                                                    <Col md={12} className='demand-box'>
                                                        <h4>Demand</h4>
                                                        <div>
                                                            <Image src="assets/images/demand.svg" className='img-fluid' alt='demand'/>
                                                            <div>
                                                                <h5>40% Increase</h5>
                                                                <h6>Anticipated in Project Management-oriented roles globally by 2027</h6>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="project-lead" title="Project Lead">
                                                Tab content for Project Lead
                                            </Tab>
                                            <Tab eventKey="pmo-lead" title="PMO Lead">
                                                Tab content for PMO Lead
                                            </Tab>
                                            <Tab eventKey="project-director" title="Project Director">
                                                Tab content for Project Director
                                            </Tab>                                            
                                        </Tabs>                                    
                                    </Col>
                                </Row>                         

                            </Col>
                            <Col md={4} lg={4} className='cousrse-sidebar'>

                                <Row>
                                    <Col md={12} className='over-certi'>
                                        <div>
                                            <Image src="assets/images/certificate.png" className='img-fluid' alt='certificate'/>
                                            <Link to="/">Sharable on <Image src="assets/images/linkedin-logo.png" className='img-fluid' alt='linkedin-logo'/> </Link>
                                        </div>
                                    </Col>                            
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <div className='cou-sidebar-form'>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formGridPassword">
                                                    <Form.Control type="text" placeholder="Full Name*" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                                    <Form.Control type="email" placeholder="Email*" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formGridAddress2">
                                                    <Form.Control type="text" placeholder="Phone Number" />
                                                </Form.Group>
                                                <p>By Signing up, you agree to our <Link to='/terms-and-conditions' onClick={() => window.scrollTo(0, 0)}>Terms & Conditions</Link> and our <Link to='/privacy-policy' onClick={() => window.scrollTo(0, 0)}>Privacy and Policy</Link></p>
                                                <Button variant="primary" type="submit">
                                                    Talk to our advisor
                                                </Button>
                                            </Form>
                                        </div>
                                        <div className='cou-quote'>
                                            <div>
                                                <Image src="assets/images/queemoji.svg" className='img-fluid' alt='queemoji'/>
                                                <div>
                                                    <h5>Struggling to Find the</h5> 
                                                    <h6>Perfect Schedule?</h6>
                                                </div>
                                            </div>
                                            <Link to=''>View all Schedules</Link>
                                        </div>                                    
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='sasu-box'>
                    <Container>
                        <Row>
                            <Col>
                                <h3 className='sasu-title'>Students also signed up for</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Row className='pctop'>
                                    <span>Data Science</span>
                                    <Link to="/course-detail"><Image src="assets/images/pc1.png" className='img-fluid' alt='pc1'/></Link>
                                    <Image src="assets/images/sa.png" className='img-fluid pct-abt' alt='sa'/>
                                </Row>
                                <Row className='pcbot'>
                                    <Col>
                                        <Col className='pcbotwrap'>
                                            <Link to="/course-detail"><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                            <Row>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                    <p>100% assured results</p>
                                                </Col>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                    <p>12th August 2024</p>
                                                </Col>
                                            </Row> 
                                            <Row>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                    <p>Bonus: Certifications</p>
                                                </Col>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                    <p>2 Days Training</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <div className="col pb-rate-view">
                                                <div className='rating_bar'>
                                                    <div className='rating-dis' style={{width: '90%'}}></div>
                                                </div>
                                                <p>(67 Reviews)</p>                                     
                                            </div>
                                            </Row>                                                                              
                                            <Row className='pbplwrap'>
                                                <Col className='pbprice pe-0'>
                                                    <h4>₹ 15,499</h4>
                                                    <h5>₹18,000</h5>
                                                </Col>
                                                <Col className='pblink text-end'>
                                                    <Link to="/course-detail">Buy Course</Link>
                                                </Col>
                                            </Row>
                                        </Col>                              
                                    </Col>
                                </Row>
                            </Col> 

                            <Col md={4}>
                                <Row className='pctop'>
                                    <span>Data Science</span>
                                    <Link to="/course-detail"><Image src="assets/images/pc2.png" className='img-fluid' alt='pc2'/></Link>
                                    <Image src="assets/images/rte.png" className='img-fluid pct-abt' alt='rte'/>
                                </Row>
                                <Row className='pcbot'>
                                    <Col>
                                        <Col className='pcbotwrap'>
                                            <Link to="/course-detail"><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                            <Row>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                    <p>100% assured results</p>
                                                </Col>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                    <p>12th August 2024</p>
                                                </Col>
                                            </Row> 
                                            <Row>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                    <p>Bonus: Certifications</p>
                                                </Col>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                    <p>2 Days Training</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <div className="col pb-rate-view">
                                                <div className='rating_bar'>
                                                    <div className='rating-dis' style={{width: '90%'}}></div>
                                                </div>
                                                <p>(69 Reviews)</p>                                     
                                            </div>
                                            </Row>                                                                              
                                            <Row className='pbplwrap'>
                                                <Col className='pbprice pe-0'>
                                                    <h4>₹ 16,599</h4>
                                                    <h5>₹20,599</h5>
                                                </Col>
                                                <Col className='pblink text-end'>
                                                    <Link to="/course-detail">Buy Course</Link>
                                                </Col>
                                            </Row>
                                        </Col>                              
                                    </Col>
                                </Row>
                            </Col> 

                            <Col md={4}>
                                <Row className='pctop'>
                                    <span>Data Science</span>
                                    <Link to="/course-detail"><Image src="assets/images/pc3.png" className='img-fluid' alt='pc3'/></Link>
                                    <Image src="assets/images/sa.png" className='img-fluid pct-abt' alt='sa'/>
                                </Row>
                                <Row className='pcbot'>
                                    <Col>
                                        <Col className='pcbotwrap'>
                                            <Link to="/course-detail"><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                            <Row>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                    <p>100% assured results</p>
                                                </Col>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                    <p>12th August 2024</p>
                                                </Col>
                                            </Row> 
                                            <Row>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                    <p>Bonus: Certifications</p>
                                                </Col>
                                                <Col className='pcbottxt'>
                                                    <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                    <p>2 Days Training</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <div className="col pb-rate-view">
                                                <div className='rating_bar'>
                                                    <div className='rating-dis' style={{width: '90%'}}></div>
                                                </div>
                                                <p>(10 Reviews)</p>                                     
                                            </div>
                                            </Row>                                                                              
                                            <Row className='pbplwrap'>
                                                <Col className='pbprice pe-0'>
                                                    <h4>₹ 11,699</h4>
                                                    <h5>₹14,499</h5>
                                                </Col>
                                                <Col className='pblink text-end'>
                                                    <Link to="/course-detail">Buy Course</Link>
                                                </Col>
                                            </Row>
                                        </Col>                              
                                    </Col>
                                </Row>
                            </Col>                         
                        </Row>
                    </Container>
                </section>

            <Footer />

        </>
    )        

}
    export default CourseDetail;    