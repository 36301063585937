import React, { useEffect } from 'react';
import {Container, Row, Col, Form, Button, Image} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

import {useDropzone} from 'react-dropzone';

const maxLength = 20;

function nameLengthValidator(file) {
  if (file.name.length > maxLength) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${maxLength} characters`
    };
  }

  return null
}

const Instructor = () => {

    useEffect(() => {
          document.title = `Become an instructor | Agile Seekers`;
          document.getElementById('bodywrap').className='baimain';
    },[]);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps
      } = useDropzone({
        validator: nameLengthValidator
      });
    
      const acceptedFileItems = acceptedFiles.map(file => (
        <p key={file.path}>
          {file.path} - {file.size} bytes
        </p>
      ));
    
 





    return (
        <>
            <Navbar />

                <section className='inst-wrap'>
                    <Container>
                        <Row>
                            <Col md={6} className='inst-left px-0'>
                                <div>
                                    <h3>Join Us as an Instructor</h3>
                                    <p className='mb-3'>Share your expertise and inspire learners around the world.</p>
                                    <Form>

                                        <Form.Group className="mb-3" controlId="fname">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter your full name" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" as={Col} controlId="experience">
                                            <Form.Label>Overall Experience</Form.Label>
                                            <Form.Select defaultValue="Years of experience">
                                                <option>1 Year</option>
                                                <option>2 Year</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="contact">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control type="text" placeholder="Enter your contact number" />
                                        </Form.Group>

                                        <div {...getRootProps({ className: 'dropzone mb-3' })}>
                                            <input {...getInputProps()} />
                                            <div>
                                                <Image src="assets/images/upload.png" className='img-fluid' alt='upload'/>
                                                <p>No file chosen</p>
                                            </div>
                                            <div>
                                                <h5>Upload Resume (PDF, DOC)</h5>
                                                <h6>Resume Upload</h6>
                                            </div>
                                        </div>

                                        <p className='mb-3'>{acceptedFileItems}</p>
                                    

                                        <Form.Group className="mb-3" id="agree">
                                            <Form.Check type="checkbox" label="I agree to the terms and conditions." />
                                        </Form.Group>

                                        <Button variant="primary" type="submit">
                                            Apply Now
                                        </Button>
                                    </Form>                                    
                                </div>
                            </Col>
                            <Col md={6} className='inst-right px-0'>
                                <Image src="assets/images/join.jpg" className='img-fluid' alt='contact'/>
                            </Col>
                        </Row>
                    </Container>
                </section>

            <Footer />

        </>
    )        

}
    export default Instructor;