import React, { useEffect } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Home = () => {

    useEffect(() => {
          document.title = `Home | Agile Seekers`;
          document.getElementById('bodywrap').className='homemain';
    },[]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,              // Enable autoplay
        autoplaySpeed: 3000,         // Set the speed for autoplay (in milliseconds)
        centerMode: true,            // Enable center mode
    
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
    
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
        
      };

      const settings1 = {

        speed: 2000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false        

        };

    return (
        <>
            <Navbar />

            <section>
                <Container>
                    <Row className='hm-banner-box'>
                        <Col md={6} className='hm-banner-left'>
                            <h2><span>Get 50+</span> Best Online Courses From Agile Seekers</h2>
                            <p>Education is the key to unlocking your potential. Invest in education for a brighter future.</p>
                            <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}>Explore All Course <Image src="assets/images/wh-arrow.png" className='img-fluid' alt='tb1'/></Link>
                            <div className='rbl-box'>
                                <div className='rbl-left'>
                                    <Image src="assets/images/rbl1.png" className='img-fluid' alt='rbl1'/>
                                    <Image src="assets/images/rbl2.png" className='img-fluid' alt='rbl2'/>
                                    <Image src="assets/images/rbl3.png" className='img-fluid' alt='rbl3'/>
                                    <Image src="assets/images/rbl4.png" className='img-fluid' alt='rbl4'/>
                                    <Image src="assets/images/rbl5.png" className='img-fluid' alt='rbl5'/>
                                    <Image src="assets/images/rbl6.png" className='img-fluid' alt='rbl6'/>                                    
                                </div>
                                <div className='rbl-right'>
                                    <p>Rated by leaners</p>
                                    <div>
                                        <h5>4.8/5</h5>
                                        <h6>12,500+ Reviews</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Image src="assets/images/hm-banner.png" className='img-fluid' alt='hm-banner'/>                        
                        </Col>                        
                    </Row>
                </Container>
            </section>

            <section className='tb-main'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3>Trusted By</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='tb-box'>
                            <Slider {...settings1}>
                                <Image src="assets/images/tb1.png" className='img-fluid' alt='tb1'/>
                                <Image src="assets/images/tb2.png" className='img-fluid' alt='tb2'/>
                                <Image src="assets/images/tb3.png" className='img-fluid' alt='tb3'/>
                                <Image src="assets/images/tb4.png" className='img-fluid' alt='tb4'/>
                                <Image src="assets/images/tb5.png" className='img-fluid' alt='tb5'/>
                                <Image src="assets/images/tb6.png" className='img-fluid' alt='tb6'/>
                                <Image src="assets/images/tb7.png" className='img-fluid' alt='tb7'/>
                                <Image src="assets/images/tb8.png" className='img-fluid' alt='tb8'/>
                                <Image src="assets/images/tb9.png" className='img-fluid' alt='tb9'/>
                                <Image src="assets/images/tb10.png" className='img-fluid' alt='tb10'/>
                                <Image src="assets/images/tb11.png" className='img-fluid' alt='tb11'/>
                                <Image src="assets/images/tb12.png" className='img-fluid' alt='tb12'/>
                            </Slider>
                        </Col>                                   
                    </Row>
                </Container>
            </section>

            <section className='popular-courses'>
                <Container>
                    <Row>
                        <Col md={12} className='pc-title'>
                            <Image src="assets/images/arrow.png" className='img-fluid' alt='arrow'/>
                            <Image src="assets/images/pc.png" className='img-fluid' alt='arrow'/>
                            <Image src="assets/images/arrow.png" className='img-fluid' alt='arrow'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Row className='pctop'>
                                <span>Data Science</span>
                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><Image src="assets/images/pc1.png" className='img-fluid' alt='pc1'/></Link>
                                <Image src="assets/images/sa.png" className='img-fluid pct-abt' alt='sa'/>
                            </Row>
                            <Row className='pcbot'>
                                <Col>
                                    <Col className='pcbotwrap'>
                                        <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>100% assured results</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>12th August 2024</p>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>Bonus: Certifications</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>2 Days Training</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <div className="col pb-rate-view">
                                            <div className='rating_bar'>
                                                <div className='rating-dis' style={{width: '90%'}}></div>
                                            </div>
                                            <p>(67 Reviews)</p>                                     
                                        </div>
                                        </Row>                                                                              
                                        <Row className='pbplwrap'>
                                            <Col className='pbprice pe-0'>
                                                <h4>₹ 15,499</h4>
                                                <h5>₹18,000</h5>
                                            </Col>
                                            <Col className='pblink text-end'>
                                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}>Buy Course</Link>
                                            </Col>
                                        </Row>
                                    </Col>                              
                                </Col>
                            </Row>
                        </Col> 

                        <Col md={4}>
                            <Row className='pctop'>
                                <span>Data Science</span>
                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><Image src="assets/images/pc2.png" className='img-fluid' alt='pc2'/></Link>
                                <Image src="assets/images/rte.png" className='img-fluid pct-abt' alt='rte'/>
                            </Row>
                            <Row className='pcbot'>
                                <Col>
                                    <Col className='pcbotwrap'>
                                        <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>100% assured results</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>12th August 2024</p>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>Bonus: Certifications</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>2 Days Training</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <div className="col pb-rate-view">
                                            <div className='rating_bar'>
                                                <div className='rating-dis' style={{width: '90%'}}></div>
                                            </div>
                                            <p>(69 Reviews)</p>                                     
                                        </div>
                                        </Row>                                                                              
                                        <Row className='pbplwrap'>
                                            <Col className='pbprice pe-0'>
                                                <h4>₹ 16,599</h4>
                                                <h5>₹20,599</h5>
                                            </Col>
                                            <Col className='pblink text-end'>
                                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}>Buy Course</Link>
                                            </Col>
                                        </Row>
                                    </Col>                              
                                </Col>
                            </Row>
                        </Col> 

                        <Col md={4}>
                            <Row className='pctop'>
                                <span>Data Science</span>
                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><Image src="assets/images/pc3.png" className='img-fluid' alt='pc3'/></Link>
                                <Image src="assets/images/sa.png" className='img-fluid pct-abt' alt='sa'/>
                            </Row>
                            <Row className='pcbot'>
                                <Col>
                                    <Col className='pcbotwrap'>
                                        <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>100% assured results</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>12th August 2024</p>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>Bonus: Certifications</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>2 Days Training</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <div className="col pb-rate-view">
                                            <div className='rating_bar'>
                                                <div className='rating-dis' style={{width: '90%'}}></div>
                                            </div>
                                            <p>(10 Reviews)</p>                                     
                                        </div>
                                        </Row>                                                                              
                                        <Row className='pbplwrap'>
                                            <Col className='pbprice pe-0'>
                                                <h4>₹ 11,699</h4>
                                                <h5>₹14,499</h5>
                                            </Col>
                                            <Col className='pblink text-end'>
                                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}>Buy Course</Link>
                                            </Col>
                                        </Row>
                                    </Col>                              
                                </Col>
                            </Row>
                        </Col> 

                        <Col md={4}>
                            <Row className='pctop'>
                                <span>Data Science</span>
                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><Image src="assets/images/pc4.png" className='img-fluid' alt='pc4'/></Link>
                                <Image src="assets/images/sa.png" className='img-fluid pct-abt' alt='sa'/>
                            </Row>
                            <Row className='pcbot'>
                                <Col>
                                    <Col className='pcbotwrap'>
                                        <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>100% assured results</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>12th August 2024</p>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>Bonus: Certifications</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>2 Days Training</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <div className="col pb-rate-view">
                                            <div className='rating_bar'>
                                                <div className='rating-dis' style={{width: '90%'}}></div>
                                            </div>
                                            <p>(67 Reviews)</p>                                     
                                        </div>
                                        </Row>                                                                              
                                        <Row className='pbplwrap'>
                                            <Col className='pbprice pe-0'>
                                                <h4>₹ 14,499</h4>
                                                <h5>₹18,500</h5>
                                            </Col>
                                            <Col className='pblink text-end'>
                                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}>Buy Course</Link>
                                            </Col>
                                        </Row>
                                    </Col>                              
                                </Col>
                            </Row>
                        </Col> 

                        <Col md={4}>
                            <Row className='pctop'>
                                <span>Data Science</span>
                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><Image src="assets/images/pc5.png" className='img-fluid' alt='pc5'/></Link>
                                <Image src="assets/images/sa.png" className='img-fluid pct-abt' alt='sa'/>
                            </Row>
                            <Row className='pcbot'>
                                <Col>
                                    <Col className='pcbotwrap'>
                                        <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>100% assured results</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>12th August 2024</p>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>Bonus: Certifications</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>2 Days Training</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <div className="col pb-rate-view">
                                            <div className='rating_bar'>
                                                <div className='rating-dis' style={{width: '90%'}}></div>
                                            </div>
                                            <p>(69 Reviews)</p>                                     
                                        </div>
                                        </Row>                                                                              
                                        <Row className='pbplwrap'>
                                            <Col className='pbprice pe-0'>
                                                <h4>₹ 13,466</h4>
                                                <h5>₹14,700</h5>
                                            </Col>
                                            <Col className='pblink text-end'>
                                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}>Buy Course</Link>
                                            </Col>
                                        </Row>
                                    </Col>                              
                                </Col>
                            </Row>
                        </Col> 

                        <Col md={4}>
                            <Row className='pctop'>
                                <span>Data Science</span>
                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><Image src="assets/images/pc6.png" className='img-fluid' alt='pc6'/></Link>
                                <Image src="assets/images/sa.png" className='img-fluid pct-abt' alt='sa'/>
                            </Row>
                            <Row className='pcbot'>
                                <Col>
                                    <Col className='pcbotwrap'>
                                        <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}><h3>Grow Personal Financial Security Thinking & Principles</h3></Link>
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>100% assured results</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pctime.png" className='img-fluid' alt='pctime'/>
                                                <p>12th August 2024</p>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>Bonus: Certifications</p>
                                            </Col>
                                            <Col className='pcbottxt'>
                                                <Image src="assets/images/pcuser.png" className='img-fluid' alt='pcuser'/>
                                                <p>2 Days Training</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <div className="col pb-rate-view">
                                            <div className='rating_bar'>
                                                <div className='rating-dis' style={{width: '90%'}}></div>
                                            </div>
                                            <p>(10 Reviews)</p>                                     
                                        </div>
                                        </Row>                                                                              
                                        <Row className='pbplwrap'>
                                            <Col className='pbprice pe-0'>
                                                <h4>₹ 11,966</h4>
                                                <h5>₹12,300</h5>
                                            </Col>
                                            <Col className='pblink text-end'>
                                                <Link to="/course-detail" onClick={() => window.scrollTo(0, 0)}>Buy Course</Link>
                                            </Col>
                                        </Row>
                                    </Col>                              
                                </Col>
                            </Row>
                        </Col>                                                                                                                         
                    </Row>
                </Container>
            </section>

            <section className='hm-learn'>
                <Container>
                    <Row>
                        <Col><h3>Learn from Experts Who've Been There and Done That</h3></Col>
                    </Row>
                    <Row className='hmicnwrap mx-0'>
                        <Col md={3} xs={6}>
                            <Row className='hmicn'>
                                <Col md={'auto'}>
                                    <Image src="assets/images/leicn1.png" className='img-fluid' alt='leicn1'/>                        
                                </Col>
                                <Col>
                                    <h4 className='leicn1'>800+</h4>
                                    <p>Number of people trained</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} xs={6}>
                            <Row className='hmicn'>
                                <Col md={'auto'}>
                                    <Image src="assets/images/leicn2.png" className='img-fluid' alt='leicn2'/>                        
                                </Col>
                                <Col>
                                    <h4 className='leicn2'>20</h4>
                                    <p>Countries covered</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} xs={6}>
                            <Row className='hmicn'>
                                <Col md={'auto'}>
                                    <Image src="assets/images/leicn3.png" className='img-fluid' alt='leicn3'/>                        
                                </Col>
                                <Col>
                                    <h4 className='leicn3'>92+</h4>
                                    <p>Certification pass rate</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} xs={6}>
                            <Row className='hmicn'>
                                <Col md={'auto'}>
                                    <Image src="assets/images/leicn4.png" className='img-fluid' alt='leicn4'/>                        
                                </Col>
                                <Col>
                                    <h4 className='leicn4'>18+</h4>
                                    <p>Year of experience</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='hm-oet-main'>
                <Container fluid>
                    <Row>
                        <Col><h3>Our Expert Trainers</h3></Col>
                    </Row>
                    <Row>

                        <Col md={3}>
                            <Row className='oet-top mx-0'>
                                <Col>
                                    <Image src="assets/images/oet1.png" className='img-fluid' alt='oet1'/>                        
                                </Col>                                
                            </Row>
                            <Row className='oet-bottom mx-0'>
                                <Col md={12} className='oet-text'>
                                    <h4>Trainer Name</h4>
                                    <h5>Node.js developer</h5>
                                </Col>
                                <Col md={12}>
                                    <p>Deliver business value to our customers by coaching and mentoring people to foster an agile culture in enterprises.</p>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={3}>
                            <Row className='oet-top mx-0'>
                                <Col>
                                    <Image src="assets/images/oet2.png" className='img-fluid' alt='oet2'/>                        
                                </Col>                                
                            </Row>
                            <Row className='oet-bottom mx-0'>
                                <Col md={12} className='oet-text'>
                                    <h4>Trainer Name</h4>
                                    <h5>Node.js developer</h5>
                                </Col>
                                <Col md={12}>
                                    <p>Deliver business value to our customers by coaching and mentoring people to foster an agile culture in enterprises.</p>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={3}>
                            <Row className='oet-top mx-0'>
                                <Col>
                                    <Image src="assets/images/oet3.png" className='img-fluid' alt='oet3'/>                        
                                </Col>                                
                            </Row>
                            <Row className='oet-bottom mx-0'>
                                <Col md={12} className='oet-text'>
                                    <h4>Trainer Name</h4>
                                    <h5>Node.js developer</h5>
                                </Col>
                                <Col md={12}>
                                    <p>Deliver business value to our customers by coaching and mentoring people to foster an agile culture in enterprises.</p>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={3}>
                            <Row className='oet-top mx-0'>
                                <Col>
                                    <Image src="assets/images/oet4.png" className='img-fluid' alt='oet4'/>                        
                                </Col>                                
                            </Row>
                            <Row className='oet-bottom mx-0'>
                                <Col md={12} className='oet-text'>
                                    <h4>Trainer Name</h4>
                                    <h5>Node.js developer</h5>
                                </Col>
                                <Col md={12}>
                                    <p>Deliver business value to our customers by coaching and mentoring people to foster an agile culture in enterprises.</p>
                                </Col>
                            </Row>
                        </Col>                                                                        
                    </Row>
                </Container>
            </section>

            <section className='hm-testimonial'>
                <Container fluid>
                    <Row>
                        <Col className='hm-testi-top'>
                            <p>Why They Trust Us</p>
                            <h3>Testimonials from Our Learners</h3>                                
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Slider {...settings}>
                                <Col md={4} className='hm-testimain'>
                                    <Col className='testibox'>
                                        <div className='rating_bar'>
                                            <div className='rating-dis' style={{width: '90%'}}></div>
                                        </div>     
                                        <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                        <div>
                                            <div>
                                                <h4>Mames Mary</h4>
                                                <h5>Designer</h5>
                                            </div>
                                            <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                        </div>
                                    </Col>
                                    <Image src="assets/images/testi1.png" className='img-fluid testiuser' alt='testi1'/>
                                </Col>
                                <Col md={4} className='hm-testimain'>
                                    <Col className='testibox'>
                                        <div className='rating_bar'>
                                            <div className='rating-dis' style={{width: '90%'}}></div>
                                        </div>     
                                        <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                        <div>
                                            <div>
                                                <h4>Rhys Chandler</h4>
                                                <h5>Science Student</h5>
                                            </div>
                                            <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                        </div>
                                    </Col>
                                    <Image src="assets/images/testi2.png" className='img-fluid testiuser' alt='testi2'/>
                                </Col>
                                <Col md={4} className='hm-testimain'>
                                    <Col className='testibox'>
                                        <div className='rating_bar'>
                                            <div className='rating-dis' style={{width: '90%'}}></div>
                                        </div>     
                                        <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                        <div>
                                            <div>
                                                <h4>Mames Mary</h4>
                                                <h5>Designer</h5>
                                            </div>
                                            <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                        </div>
                                    </Col>
                                    <Image src="assets/images/testi3.png" className='img-fluid testiuser' alt='testi3'/>
                                </Col> 
                                <Col md={4} className='hm-testimain'>
                                    <Col className='testibox'>
                                        <div className='rating_bar'>
                                            <div className='rating-dis' style={{width: '90%'}}></div>
                                        </div>     
                                        <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                        <div>
                                            <div>
                                                <h4>Mames Mary</h4>
                                                <h5>Designer</h5>
                                            </div>
                                            <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                        </div>
                                    </Col>
                                    <Image src="assets/images/testi1.png" className='img-fluid testiuser' alt='testi1'/>
                                </Col>
                                <Col md={4} className='hm-testimain'>
                                    <Col className='testibox'>
                                        <div className='rating_bar'>
                                            <div className='rating-dis' style={{width: '90%'}}></div>
                                        </div>     
                                        <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                        <div>
                                            <div>
                                                <h4>Rhys Chandler</h4>
                                                <h5>Science Student</h5>
                                            </div>
                                            <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                        </div>
                                    </Col>
                                    <Image src="assets/images/testi2.png" className='img-fluid testiuser' alt='testi2'/>
                                </Col>
                                <Col md={4} className='hm-testimain'>
                                    <Col className='testibox'>
                                        <div className='rating_bar'>
                                            <div className='rating-dis' style={{width: '90%'}}></div>
                                        </div>     
                                        <p>" Histudy The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."</p>                           
                                        <div>
                                            <div>
                                                <h4>Mames Mary</h4>
                                                <h5>Designer</h5>
                                            </div>
                                            <Image src="assets/images/linkedin-blue.png" className='img-fluid' alt='linkedin-blue'/>
                                        </div>
                                    </Col>
                                    <Image src="assets/images/testi3.png" className='img-fluid testiuser' alt='testi3'/>
                                </Col>                                                                                             
                            </Slider>                         
                        </Col>
                    </Row>
                </Container>                                    
            </section>

            <section className='hm-about-main'>
                <Container>
                    <Row>
                        <Col md={5}>
                            <Image src="assets/images/hm-about.png" className='img-fluid' alt='hm-about'/> 
                        </Col>
                        <Col md={7}>
                            <Row className='ham-top'>
                                <Col md={12}>
                                    <h3>About Us</h3>
                                    <Image src="assets/images/plan.png" className='img-fluid' alt='plan'/> 
                                </Col>
                                <Col md={12}>
                                    <p>Agile transformation is the process of transitioning an entire organization to one that can embrace and thrive in a flexible, collaborative, self-organizing, fast-changing environment. Many organizations have been building software and related products for years. The world has decided to move much faster and build smaller software in incremental steps. The complete cultural and organizational mind set must change to one that embraces a culture of self-organization and collaboration. An agile transformation changes the fundamental DNA of an organization.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Col className='ham-orange'>
                                        <Col className='text-end'>
                                            <Image src="assets/images/au-star.png" className='img-fluid' alt='au-star'/>
                                        </Col>                                             
                                        <Col>
                                            <Image src="assets/images/au-view.png" className='img-fluid' alt='au-view'/> 
                                            <h5>Our Vision</h5>
                                        </Col>
                                        <p>To be always the most desired place for reliable learning and inspiring people globally by spreading Agile exuberantly.</p>                                
                                    </Col>
                                </Col>
                                <Col md={6}>
                                    <Col className='ham-orange'>
                                        <Col className='text-end'>
                                            <Image src="assets/images/au-star.png" className='img-fluid' alt='au-star'/>
                                        </Col>                                             
                                        <Col>
                                            <Image src="assets/images/au-view.png" className='img-fluid' alt='au-view'/> 
                                            <h5>Our Mission</h5>
                                        </Col>
                                        <p>Deliver business value to our customers by coaching and mentoring people to foster an agile culture in enterprises.</p>                                
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />

        </>
    )        

}
    export default Home;    