import React, { useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

const Demo = () => {

    useEffect(() => {
          document.title = `Demo | Agile Seekers`;
          document.getElementById('bodywrap').className='demomain';
    },[]);




    return (
        <>
            <Navbar />

                <section>
                    <Container>
                        <Row>
                            <Col></Col>
                        </Row>
                    </Container>
                </section>

            <Footer />

        </>
    )        

}
    export default Demo;    